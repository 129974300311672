<template>
  <!-- 产品服务 -->
  <div class="product">
    <div class="box_top">
      <img :src="container.coverPic"/>
    </div>
    <!-- 物流时效透明化 -->
    <div class="productBox">
      <!-- 到达国家 -->
      <el-row :gutter="10">
        <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
          <div class="inputBox">
            <span> {{ langData["price.label.des"] }}:</span>
            <el-select style="width: 100%" v-model="getPriceForm.des" :placeholder="langData['price.input.des']">
              <el-option v-for="(item, index) in desList" :key="index" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <!-- 货物重量 -->
        <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
          <div class="inputBox">
            <span>{{ langData["price.label.weight"] }}:</span>
            <el-input v-model="getPriceForm.weight" :placeholder="langData['price.input.weight']">
              <i slot="suffix" type="text">KG</i>
            </el-input>
          </div>
        </el-col>
        <!-- 快递渠道 -->
        <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
          <div class="inputBox">
            <span>{{ langData["price.label.model"] }}:</span>
            <el-select style="width: 100%" v-model="getPriceForm.expressModelCode"
                       :placeholder="langData['price.input.model']">
              <el-option v-for="item in channelList" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <!-- 查询 -->
        <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
          <div>
            <el-button type="primary" @click="getPrice">
              {{ langData["price.btn.query"] }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-table v-loading="loading" element-loading-text="Loading" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="tableData" stripe style="width: 100%"
                :header-cell-style="setTitleStyle">
        <el-table-column label="#" width="70px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="langData['price.modelName']"></el-table-column>
        <el-table-column prop="chargedMark" :label="langData['price.chargedMark']"></el-table-column>
        <el-table-column prop="price" :label="langData['price.price']"></el-table-column>
        <el-table-column prop="estimatedTime" :label="langData['price.estimatedTime']"></el-table-column>
      </el-table>

      <div v-html="container.content"></div>
    </div>
  </div>
</template>
<script>
import axios from "../assets/axios/axios";
import {mapState} from "vuex";

export default {
  components: {},
  data() {
    return {
      loading: false,
      // 查询时效价格表单
      getPriceForm: {
        // 国家
        des: "",
        // 重量
        weight: null,
        // 渠道
        expressModelCode: "",
      },
      // 国家列表
      desList: [],
      // 渠道列表
      channelList: [],
      // table数据
      tableData: [],

      // 设置table 表头样式
      setTitleStyle: {
        background: "#e6e7e9",
        color: "black",
      },
    };
  },
  computed: {
    ...mapState(["langData", "container"]),
  },
  created() {
    this.getPriceContent();
    this.getDes();
    this.getModel();
  },
  methods: {
    // 时效价格查询
    getPrice() {
      this.loading = true;
      axios.getPrice(this.getPriceForm).then((res) => {
        const data = res.data;
        this.tableData = data.result;
        this.loading = false;
      });
    },
    // 快递渠道查询
    getModel() {
      axios.getModel().then((res) => {
        const data = res.data;
        this.channelList = data.result;
      });
    },
    // 到达国家查询
    getDes() {
      axios.getDes().then((res) => {
        const data = res.data;
        this.desList = data.result;
      });
    },
    // 页面内容
    getPriceContent() {
      axios.getPriceContent().then((res) => {
        const {data} = res.data;
        this.$store.commit("getContainer", data);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box_top {
  width: 100%;

  img {
    width: 100%;
  }
}

/* 物流时效透明化 */
.productBox {
  width: 100%;
  padding: 0 5% 3rem 5%;

  .el-row {
    margin: 3rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .inputBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-col {
    span {
      font-weight: bold;
      width: 6.25rem;
    }

    /deep/ .el-input {
      max-width: 20rem;

      .el-input__inner {
        height: 3rem;
      }

      .el-input__suffix {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
      }
    }

    .el-select {
      max-width: 20rem;
      height: 3rem;

      .el-input__inner {
        height: 3rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .productBox {
    .inputBox {
      justify-content: space-between;

      margin-bottom: 1rem;

      /deep/ .el-input {
        width: 15.625rem !important;
      }

      .el-select {
        width: 15.625rem !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .el-card__body {
    margin: 2rem 3rem;
    padding: 1rem 0 2rem 0;

    /* .el-timeline-item__wrapper {
      display: inline-block !important;
    } */
  }
}
</style>
